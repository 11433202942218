import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 2-2-2-2-2 @ 90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`15-Burpees Over Bar`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids today at East at 9:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Memorial Day Murph will take place next Monday at Louisville East
at 10:00am!  All are welcome.  All other classes and open gym will be
cancelled for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      